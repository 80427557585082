.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 9999;
  }
  
  .arrow-icon {
    font-size: 30px;
    color: #0A56C5; /* Replace with your desired color */
    background-color: #212529; /* Replace with your desired background color */
    padding: 10px;
    border-radius: 50%;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
    transition: background-color 0.3s ease-in-out;
  }
  
  .arrow-icon:hover {
    background-color: #007bff; /* Replace with your desired hover background color */
    color: #ffffff; /* Replace with your desired hover color */
  }